import { None, Option, Some } from './monads';

/**
 * Clamp a value between two bounds
 */
export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Round the number to a specific decimal
 */
export function roundToDecimal(value: number, decimal: number) {
  return Math.round(value * 10 ** decimal) / 10 ** decimal;
}

/**
 * Finds the first element of an array that respects a predicate by using dichotomy. The array must at least be
 * partitioned in respect to the predicate (all the elements for which the predicate returns `false` must be before
 * all the elements for which it returns `true`).
 * @returns the index of the first element that respects the predicate, or the `None` variant if no element respects
 * it.
 */
export function binaryFindFirst<T>(array: T[], predicate: (element: T) => boolean): Option<number> {
  let low = 0;
  let high = array.length - 1;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (!predicate(array[mid])) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  if (low === array.length) {
    return None;
  } else {
    return Some(low);
  }
}

/**
 * Find the first element of an array that is greater or equal to a value by using dichotomy. The array must at least be
 * partitioned in respect to the expression `element < value` or `compareFn(element, value)` if it is specified (all
 * the elements that are strictly less than value must be before the elements that are greater or equal to the value)
 * A sorted array meets this criterion.
 * @param array the array on which the search is done
 * @param value the value for which a greater or equal element is searched
 * @param compareFn a function that returns true if the first argument is less that the second.
 * @returns the index of the first element that is greater or equal to the value, or the `None` variant if there is
 * none.
 */
export function lowerBound<T>(
  array: T[],
  value: T,
  compareFn: (lhs: T, rhs: T) => boolean = (lhs, rhs) => lhs < rhs
): Option<number> {
  return binaryFindFirst(array, (element: T) => !compareFn(element, value));
}
