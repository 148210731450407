import 'dayjs/locale/fr';

import dayjs, { OpUnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// This plugin is required by parseDateFromFormat
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.locale('fr');

/**
 * Add days to a date
 */
export function addDays(date: Date, days: number): Date {
  return dayjs(date).add(days, 'day').toDate();
}

/**
 * Add months to a date
 */
export function addMonths(date: Date, months: number): Date {
  return dayjs(date).add(months, 'months').toDate();
}

/**
 * Add years to a date
 */
export function addYears(date: Date, years: number): Date {
  return dayjs(date).add(years, 'years').toDate();
}

/**
 * Parse date with a custom format
 */
export function formatDate(date: Date | number, format: string): string {
  return dayjs(date).format(format);
}

/**
 * Parses a string representing a date with a given format to a Date object
 */
export function parseDateFromFormat(dateString: string, format: string): Date {
  return dayjs(dateString, format).toDate();
}

/**
 * Return the day start timestamp
 */
export function getDayTime(date: Date): number {
  return getCroppedTime(date, 'day');
}

export function createDateFromDayTime(ms: number): Date {
  return dayjs().startOf('day').add(ms, 'milliseconds').toDate();
}

/**
 * Return Day label from weekday number (0 to 6)
 */
export function getDayFromWeekDay(weekday: number): string {
  return dayjs().weekday(weekday).format('dddd');
}

/**
 * Return the day with day, week,... time cropped
 */
export function getCroppedTime(date: Date, startOf: OpUnitType): number {
  return dayjs(date).startOf(startOf).valueOf();
}

/**
 * Return time between two day
 */
export function getTimeBetween(date1: Date, date2: Date, inMinutes = false) {
  const hours = dayjs(date1).diff(date2, 'h');
  const totalMinutes = dayjs(date1).diff(date2, 'm');

  if (inMinutes) {
    return `${totalMinutes}min`;
  }

  const minutes = totalMinutes - 60 * hours;
  return hours > 0 ? `${hours}h${minutes.toString().padStart(2, '0')}` : `${minutes}min`;
}

/**
 * Return if both date are the same day
 */
export function isSameDay(date1: Date, date2: Date) {
  return dayjs(date1).isSame(date2, 'day');
}

// Get the week day number between 0 and 6
export function getWeekDay(date: Date): number {
  return dayjs(date).weekday();
}

// get the number of milliseconds since the start of the day
export function getTimeOfDay(date: Date): number {
  return dayjs(date).diff(dayjs(date).startOf('day'));
}

export function getAllRange() {
  return {
    start: dayjs(0).startOf('day').toDate(),
    end: dayjs().endOf('day').toDate(),
  };
}

export function getTodayRange() {
  return {
    start: dayjs().startOf('day').toDate(),
    end: dayjs().endOf('day').toDate(),
  };
}

export function getYesterdayRange() {
  return {
    start: dayjs().subtract(1, 'day').startOf('day').toDate(),
    end: dayjs().subtract(1, 'day').endOf('day').toDate(),
  };
}

export function getThisWeekRange() {
  return {
    start: dayjs().startOf('week').toDate(),
    end: dayjs().endOf('week').toDate(),
  };
}

export function getLastWeekRange() {
  return {
    start: dayjs().subtract(1, 'week').startOf('week').toDate(),
    end: dayjs().subtract(1, 'week').endOf('week').toDate(),
  };
}

export function getThisMonthRange() {
  return {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate(),
  };
}

export function getLastMonthRange() {
  return {
    start: dayjs().subtract(1, 'month').startOf('month').toDate(),
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
  };
}

export function getThisYearRange() {
  return {
    start: dayjs().startOf('year').toDate(),
    end: dayjs().endOf('year').toDate(),
  };
}

export function getLastYearRange() {
  return {
    start: dayjs().subtract(1, 'year').startOf('year').toDate(),
    end: dayjs().subtract(1, 'year').endOf('year').toDate(),
  };
}
