import { CloudFetchService } from '@eurika/core';
import { useQuery } from '@tanstack/react-query';

export const USER_ROOT_KEY = 'users' as const;
export const ENTITLEMENTS_ROOT_KEY = 'entitlements' as const;
export const INVOICES_ROOT_KEY = 'invoices' as const;

export const useUser = (userId: string) => {
  return useQuery({
    queryKey: [USER_ROOT_KEY],
    queryFn: () => CloudFetchService.getCurrentUser({ userId }),
    initialData: undefined,
  });
};
