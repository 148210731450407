import { Err, Ok, Result } from '@eurika/utils';
import { FirebaseError } from 'firebase/app';
import type { CollectionReference, FieldValue, FirestoreError, Timestamp } from 'firebase/firestore';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';

import { DatabaseEntry, DRef, ID, UnRef } from '../models';

class BuilderEntry<T> {
  value?: T = undefined;
  needGeneratedValue = false;
}

export class DatabaseEntryBuilder<T extends UnRef<DatabaseEntry<T>>> {
  private data: T;

  private id: BuilderEntry<string> = new BuilderEntry();
  private createdAt: BuilderEntry<FieldValue> = new BuilderEntry();

  private constructor(data: T) {
    this.data = data;
  }

  static create<T extends UnRef<T>>(data: T): DatabaseEntryBuilder<T> {
    return new DatabaseEntryBuilder(data);
  }

  withId(id: ID): this {
    this.id.value = id;
    this.id.needGeneratedValue = false;

    return this;
  }

  withGeneratedCreatedAt(): this {
    this.createdAt.needGeneratedValue = true;

    return this;
  }

  async insert<U>(collection: CollectionReference<U>): Promise<Result<DRef<U>, FirebaseError | { message: string }>> {
    const createdData = this.get();

    const documentRef = this.id.value !== undefined ? doc(collection, this.id.value) : doc(collection);

    // Force type
    return setDoc(documentRef, createdData as unknown as U, { merge: true })
      .then(() => Ok(documentRef))
      .catch((error: FirestoreError) => Err(error));
  }

  get(): T {
    const injectedData = { ...this.data };

    this.injectCreatedAt(injectedData);

    return injectedData;
  }

  private injectCreatedAt(data: T): void {
    if (this.createdAt.needGeneratedValue) {
      // Force type to prevent error with different timestamp types
      data.createdAt = serverTimestamp() as Timestamp;
    } else {
      delete data.createdAt;
    }
  }
}
