import axios from 'axios';

export namespace TestUtils {
  /**
   * Clears the Firestore emulator data. Should be called before each test relying on the emulator data.
   * @param projectId the Firebase project id
   * @param firestorePort the port on which the Firestore emulator is running. If not specified, uses 9090 as the default value
   */
  export async function clearFirestore(projectId: string, host = 'localhost', firestorePort = 9090) {
    await axios.delete(
      `http://${host}:${firestorePort}/emulator/v1/projects/${projectId}/databases/(default)/documents`
    );
  }

  export async function wait(duration = 3000) {
    return new Promise(resolve => setTimeout(resolve, duration));
  }
}
