import { ContactsDatabaseService, emailValidator } from '@eurika/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import TextArea from './inputs/TextArea';
import TextInput from './inputs/TextInput';

const ContactFromDataSchema = yup
  .object({ email: emailValidator, content: yup.string().required('Le message ne peut pas être vide') })
  .required();

export type ContactFromData = yup.InferType<typeof ContactFromDataSchema>;

interface Props {
  onSubmit?: (messageSent: boolean) => void;
}

const ContactFrom: FunctionComponent<Props> = ({ onSubmit }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ContactFromData>({
    resolver: yupResolver(ContactFromDataSchema),
  });

  const handleFormSubmit = async (formData: ContactFromData) => {
    const messageSent = await ContactsDatabaseService.createContact({
      email: formData.email,
      message: formData.content,
    })
      .then(() => {
        addToast({ type: 'success', title: 'Contact', description: 'Votre message à bien été envoyé.' });

        return true;
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Contact',
          description: "Une erreur c'est produite lors de l'envois de votre message",
        });

        return false;
      });

    onSubmit?.(messageSent);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextInput
        {...register('email')}
        error={errors.email}
        label="Adresse mail"
        type="email"
        placeholder="Entrer votre adresse mail ici..."
      />
      <TextArea
        {...register('content')}
        error={errors.content}
        label="Message"
        placeholder="Entrer votre message ici..."
      />

      <div className="form-control mt-6">
        <Button color="primary" type="submit" isLoading={isSubmitting}>
          Envoyer
        </Button>
      </div>
    </form>
  );
};

export default ContactFrom;
