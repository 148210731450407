import produce from 'immer';
import create from 'zustand';

interface MetaStoreData {
  isStoreFetching: boolean;
  setStoreFetching: (isFetching: boolean) => void;
  routeSegments: Set<string>;
  areRouteSegmentsFetching: (routeId: string) => boolean;
  setRouteSegmentsFetching: (routeId: string, fetching: boolean) => void;
  segmentPoints: Set<string>;
  areSegmentPointsFetching: (segmentId: string) => boolean;
  setSegmentPointsFetching: (segmentId: string, fetching: boolean) => void;
  segmentStartAddress: Set<string>;
  isSegmentStartAddressFetching: (segmentId: string) => boolean;
  setSegmentStartAddressFetching: (segmentId: string, fetching: boolean) => void;
  segmentEndAddress: Set<string>;
  isSegmentEndAddressFetching: (segmentId: string) => boolean;
  setSegmentEndAddressFetching: (segmentId: string, fetching: boolean) => void;
}

export const useMetaStore = create<MetaStoreData>((set, get) => ({
  isStoreFetching: false,
  setStoreFetching: (isFetching: boolean) =>
    set(
      produce((state: MetaStoreData) => {
        state.isStoreFetching = isFetching;
      })
    ),
  routeSegments: new Set(),
  areRouteSegmentsFetching: (routeId: string) => get().routeSegments.has(routeId),
  setRouteSegmentsFetching: (routeId: string, fetching: boolean) =>
    set(
      produce((state: MetaStoreData) => {
        if (fetching) {
          state.routeSegments.add(routeId);
        } else {
          state.routeSegments.delete(routeId);
        }
      })
    ),
  segmentPoints: new Set(),
  areSegmentPointsFetching: (segmentId: string) => get().segmentPoints.has(segmentId),
  setSegmentPointsFetching: (segmentId: string, fetching: boolean) =>
    set(
      produce((state: MetaStoreData) => {
        if (fetching) {
          state.segmentPoints.add(segmentId);
        } else {
          state.segmentPoints.delete(segmentId);
        }
      })
    ),
  segmentStartAddress: new Set(),
  isSegmentStartAddressFetching: (segmentId: string) => get().segmentStartAddress.has(segmentId),
  setSegmentStartAddressFetching: (segmentId: string, fetching: boolean) =>
    set(
      produce((state: MetaStoreData) => {
        if (fetching) {
          state.segmentStartAddress.add(segmentId);
        } else {
          state.segmentStartAddress.delete(segmentId);
        }
      })
    ),
  segmentEndAddress: new Set(),
  isSegmentEndAddressFetching: (segmentId: string) => get().segmentEndAddress.has(segmentId),
  setSegmentEndAddressFetching: (segmentId: string, fetching: boolean) =>
    set(
      produce((state: MetaStoreData) => {
        if (fetching) {
          state.segmentEndAddress.add(segmentId);
        } else {
          state.segmentEndAddress.delete(segmentId);
        }
      })
    ),
}));
