import { Navigate, Route } from '@tanstack/react-location';

import authenticatedRoutes from './authenticated/_route';
import publicRoutes from './public/_route';

// Definition of all routes
export const routes: Route[] = [
  { path: '/', element: <Navigate to="/mon-compte" /> },
  ...authenticatedRoutes,
  ...publicRoutes,
];
