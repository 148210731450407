import { forwardRef, HTMLInputTypeAttribute, ReactNode } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface Props {
  label: string;
  error?: FieldError;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  children?: ReactNode;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextInput = forwardRef<HTMLInputElement, Props & ReturnType<UseFormRegister<any>>>(
  ({ label, name, type, placeholder, required, onChange, onBlur, error, children, className }, ref) => {
    return (
      <div className={`form-control ${className}`}>
        <label className="label">
          <span className="label-text">{label}</span>
          {required === true ? <span className="font-bold text-primary">*</span> : null}
          {children !== undefined ? <label className="label">{children}</label> : null}
        </label>
        <input
          className="input input-bordered bg-transparent"
          name={name}
          type={type}
          placeholder={placeholder}
          ref={ref}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error ? (
          <label className="label">
            <span className="label-text-alt text-error">{error.message}</span>
          </label>
        ) : null}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
export default TextInput;
