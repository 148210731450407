import { Link, MakeGenerics, useSearch } from '@tanstack/react-location';
import { FunctionComponent, useState } from 'react';

import BetaAccessRequestForm from '../../components/forms/BetaAccessRequestForm';
import { Button } from '../../components/ui/Button';
import { Card } from '../../components/ui/Card';

type LocationGenerics = MakeGenerics<{
  Search: {
    from?: string;
  };
}>;

const BetaAccessRequest: FunctionComponent = () => {
  const search = useSearch<LocationGenerics>();

  const [hasBeenSent, setHasBeenSent] = useState(false);

  const handleOnSubmit = (hasBeenSent: boolean) => {
    setHasBeenSent(hasBeenSent);
  };

  return (
    <div className="hero min-h-screen">
      <div className="hero-content flex-col gap-8 justify-center text-center">
        <div>
          <img src="/icon-horizontal.webp" alt="logo Eurika" />
        </div>

        {hasBeenSent ? (
          <Card className="gap-4">
            <h1 className="prose prose-2xl text-primary font-bold">Demande prise en compte !</h1>
            <p className="text-neutral-content">
              Nous avons bien prit en compte votre demande d'accès à notre bêta fermée.
            </p>
            {search.from !== undefined ? (
              <a href={search.from}>
                <Button interactive={false} color="primary">
                  Retour
                </Button>
              </a>
            ) : null}
          </Card>
        ) : (
          <Card>
            <h1 className="prose prose-2xl max-w-full">Participez à la bêta fermée</h1>
            <p className="text-neutral-content">
              Ne perdez plus de temps avec vos <strong>IK</strong>, téléchargez <strong>Eurika</strong>! Eurika est en
              fin de développement, nous avons besoin de votre aide pour créer{' '}
              <strong>l'application qui vous correspond</strong>. En participant à la bêta, vous avez l'occasion de
              tester l'application <strong>gratuitement</strong> en <strong>avant-première</strong>. De plus, chaque
              retour sera pris en compte afin de <strong>créer ensemble</strong> un outil qui facilite votre quotidien.
            </p>
            <BetaAccessRequestForm onSubmit={handleOnSubmit} />
          </Card>
        )}

        <p>
          Aller sur la{' '}
          <Link to="/">
            <span className="link">page d'accueil</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default BetaAccessRequest;
