import { z } from 'zod';

const firestoreTimestamp = z.any();

const firestoreTimestampOrDate = z.union([z.date(), firestoreTimestamp]).transform(elt => {
  if (elt === null) {
    return null;
  }

  if ('toDate' in elt) {
    return elt.toDate() as Date;
  }

  return elt as Date | undefined;
});

export const checkoutSchema = z.object({
  cancel_url: z.string(),
  client: z.string(),
  created: firestoreTimestampOrDate,
  mode: z.enum(['payment', 'setup', 'subscription']),
  price: z.string().startsWith('price_'),
  sessionId: z.string(),
  success_url: z.string(),
  url: z.string(),
  trial_from_plan: z.boolean().optional(),
  trial_period_days: z.number().optional(),
  allow_promotion_codes: z.boolean().optional(),
  tax_id_collection: z.boolean().optional(),
  automatic_tax: z.boolean().optional(),
  metadata: z.object({}).optional(),
});
export type CheckoutSession = z.infer<typeof checkoutSchema>;
