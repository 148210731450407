import { FirebaseApp } from 'firebase/app';
import type { DocumentData, Firestore, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';
import { connectFirestoreEmulator, getFirestore, Timestamp } from 'firebase/firestore';

export class DatabaseService {
  private static db?: Firestore = undefined;

  static initEmulator(host = 'localhost'): void {
    const database = DatabaseService.getDatabase();
    connectFirestoreEmulator(database, host, 9090);
  }

  static initializeDatabase(app: FirebaseApp): void {
    DatabaseService.db = getFirestore(app);
  }

  static getDatabase(): Firestore {
    if (DatabaseService.db !== undefined) {
      return DatabaseService.db;
    } else {
      throw new Error('Database not initialized ');
    }
  }
}

export function getFirestoreDataConverter<T>(): FirestoreDataConverter<T> {
  return {
    toFirestore(data: T): DocumentData {
      return data as DocumentData;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      // Enhance the data payload:
      // - Inject documentRef in the data object
      // - Replace all timestamp by Date objects
      return { ...replaceTimestampWithDate(snapshot.data()), ref: snapshot.ref };
    },
  };
}

// Replace all Timestamp with Date
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceTimestampWithDate(object: any): any {
  if (Array.isArray(object)) {
    return object.map(obj => replaceTimestampWithDate(obj));
  }

  if (object === null || typeof object !== 'object') {
    return object;
  }

  const props = Object.getOwnPropertyNames(object);

  props.forEach(prop => {
    // Do not check prop if it's a Ref
    if (!prop.endsWith('Ref')) {
      if (object[prop] instanceof Timestamp) {
        object[prop] = (object[prop] as Timestamp).toDate();
      } else {
        object[prop] = replaceTimestampWithDate(object[prop]);
      }
    }
  });

  return object;
}
