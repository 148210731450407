import { ContactsDatabaseService, emailValidator } from '@eurika/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import Radio from './inputs/Radio';
import TextInput from './inputs/TextInput';

const BetaAccessRequestFormDataSchema = yup
  .object({
    email: emailValidator,
    platform: yup.string().oneOf(['ios', 'android']).required('Une plateforme doit être spécifiée'),
  })
  .required();

export type BetaAccessRequestFormData = yup.InferType<typeof BetaAccessRequestFormDataSchema>;

interface Props {
  onSubmit?: (hasBeenSent: boolean) => void;
}

const BetaAccessRequestForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<BetaAccessRequestFormData>({
    resolver: yupResolver(BetaAccessRequestFormDataSchema),
  });

  const handleFormSubmit = async (formData: BetaAccessRequestFormData) => {
    const platform = formData.platform as 'ios' | 'android';

    const hasBeenSent = await ContactsDatabaseService.createBetaAccessRequest({ email: formData.email, platform })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Inscription à la bêta',
          description: 'Votre inscription à bien été prise en compte, nous vous envoyons plus de détails au plus vite',
        });

        return true;
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Inscription à la bêta',
          description: "Une erreur c'est produite. Veuillez réessayer.",
        });

        return false;
      });

    onSubmit?.(hasBeenSent);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextInput
        {...register('email')}
        error={errors.email}
        label="Adresse mail"
        type="email"
        placeholder="Entrer votre adresse mail ici..."
      />

      <Radio
        {...register('platform')}
        error={errors.platform}
        label="Plateforme"
        options={[
          { label: 'iOS', value: 'ios' },
          { label: 'Android', value: 'android' },
        ]}
      />

      <div className="form-control mt-6">
        <Button color="primary" type="submit" isLoading={isSubmitting}>
          Accéder à la bêta
        </Button>
      </div>
    </form>
  );
};

export default BetaAccessRequestForm;
