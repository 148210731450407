import { AuthenticationService, CloudFetchService, UserEntitlement, UserPayload } from '@eurika/core';
import { Route } from '@tanstack/react-location';

import { LoaderPage } from '../../components/ui/Loader';
import { queryClient } from '../../main';
import { ENTITLEMENTS_ROOT_KEY, USER_ROOT_KEY } from '../../queries/users';
import Layout from './_layout';
import AccountPage from './AccountPage';
import SubscriptionManagementPage from './SubscriptionManagementPage';
import SubscriptionSelectionPage from './SubscriptionSelectionPage';

export type CustomUserLoaderData = {
  user: UserPayload;
  entitlement?: UserEntitlement;
};

const PENDING_MS = 600;
const PENDING_MIN_MS = 200;

// Definition of all routes
const routes: Route[] = [
  {
    path: 'mon-compte',
    element: <Layout />,
    loader: async ({ search }) => {
      // Try to connect the user
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (search.token && typeof search.token === 'string') {
        await AuthenticationService.loginWithCustomToken(search.token).catch(() => undefined);
      }

      const cachedResult = queryClient.getQueryData([USER_ROOT_KEY]);

      if (cachedResult !== undefined) {
        return { user: cachedResult };
      }

      // await the authentication check
      await AuthenticationService.signInCheck();

      const userId = AuthenticationService.getAuthenticatedUser()?.uid;
      const user =
        userId !== undefined
          ? await queryClient.fetchQuery([USER_ROOT_KEY], () => CloudFetchService.getCurrentUser({ userId }))
          : undefined;

      return { user };
    },
    pendingElement: <LoaderPage />,
    pendingMs: PENDING_MS, // Display loader after this amount of ms
    pendingMinMs: PENDING_MIN_MS, // If pending shown display it a least this amount of ms
    children: [
      {
        path: '/',
        element: <AccountPage />,
        loader: async (_, { parentMatch }) => {
          const data = await parentMatch?.loaderPromise;

          if (data?.user !== undefined) {
            const userId = (data.user as UserPayload).ref.id;
            const queryKey = [USER_ROOT_KEY, userId, ENTITLEMENTS_ROOT_KEY, 'current'];

            const cachedResult = queryClient.getQueryData(queryKey);

            if (cachedResult !== undefined && cachedResult !== null) {
              return cachedResult;
            }

            const entitlement = await queryClient.fetchQuery(queryKey, () =>
              CloudFetchService.getUserEntitlement({ userId })
            );

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (entitlement !== undefined) {
              return { entitlement: entitlement.entitlement };
            }
          }

          return {};
        },
      },
      {
        path: '/selection-abonnement',
        element: <SubscriptionSelectionPage />,
        loader: async (_, { parentMatch }) => {
          const data = await parentMatch?.loaderPromise;

          if (data?.user !== undefined) {
            const userId = (data.user as UserPayload).ref.id;
            const queryKey = [USER_ROOT_KEY, userId, ENTITLEMENTS_ROOT_KEY, 'current'];

            const cachedResult = queryClient.getQueryData(queryKey);

            if (cachedResult !== undefined && cachedResult !== null) {
              return cachedResult;
            }

            const entitlement = await queryClient.fetchQuery(queryKey, () =>
              CloudFetchService.getUserEntitlement({ userId })
            );

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (entitlement !== undefined) {
              return { entitlement: entitlement.entitlement };
            }
          }

          return {};
        },
      },
      {
        path: '/gestion-abonnement',
        element: <SubscriptionManagementPage />,
      },
    ],
  },
];

export default routes;
