import { FirebaseApp } from 'firebase/app';
import { connectFunctionsEmulator, Functions, getFunctions, httpsCallable } from 'firebase/functions';

export class CloudFunctionService {
  private static functions?: Functions = undefined;

  static initEmulator(host = 'localhost'): void {
    const functions = CloudFunctionService.getFunctions();
    connectFunctionsEmulator(functions, host, 9095);
  }

  static initializeFunctions(app: FirebaseApp): void {
    // TODO: set region in environment variable
    CloudFunctionService.functions = getFunctions(app, 'europe-west1');
  }

  static getFunctions() {
    if (CloudFunctionService.functions !== undefined) {
      return CloudFunctionService.functions;
    } else {
      throw new Error('Functions not initialized');
    }
  }

  static getFunction<RequestData = unknown, ResponseData = unknown>(name: string) {
    const functions = CloudFunctionService.getFunctions();
    return httpsCallable<RequestData, ResponseData>(functions, name);
  }
}
