import { SubscriptionStatus } from '@eurika/core';
import { FC } from 'react';

type Props = {
  status: SubscriptionStatus;
};

function getFormattedStatus(status: SubscriptionStatus): { class: string; translation: string } {
  return {
    active: { class: 'badge-primary', translation: 'Actif' },
    canceled: { class: 'badge-error', translation: 'Annulé' },
    incomplete: { class: 'badge-warning', translation: 'Incomplet' },
    incomplete_expired: { class: 'badge-warning', translation: 'Incomplet expiré' },
    past_due: { class: 'badge-error', translation: 'Retard de paiement' },
    trialing: { class: 'badge-secondary', translation: 'En essai' },
    unpaid: { class: 'badge-error', translation: 'Impayé' },
  }[status];
}

export const SubscriptionStatusBadge: FC<Props> = ({ status }) => {
  const data = getFormattedStatus(status);

  return <span className={`badge ${data.class}`}>{data.translation}</span>;
};
