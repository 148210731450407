import * as yup from 'yup';

export const emailValidator = yup
  .string()
  .trim()
  .email("L'adresse mail est invalide")
  .required("L'adresse mail est nécessaire");

export const passwordValidator = yup
  .string()
  .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
  .required('Le mot de passe est nécessaire');
