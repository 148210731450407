import { MakeGenerics, Navigate, useSearch } from '@tanstack/react-location';
import { FunctionComponent } from 'react';

import NotFound from './NotFound';

type LocationGenerics = MakeGenerics<{
  Search: {
    oobCode?: string;
    mode?: 'verifyEmail' | 'verifyAndChangeEmail' | 'resetPassword';
  };
}>;

export const Redirect: FunctionComponent = () => {
  const search = useSearch<LocationGenerics>();

  // Handle Firebase redirect with oobCode
  if (search.oobCode !== undefined) {
    // Handle email verification page
    if (['verifyEmail', 'verifyAndChangeEmail'].includes(search.mode || '')) {
      return <Navigate to="/verification-mail" search={{ oobCode: search.oobCode }} />;
    }

    // Handle password reset page
    if (search.mode === 'resetPassword') {
      return <Navigate to="/mot-de-passe-oublie" search={{ oobCode: search.oobCode }} />;
    }
  }

  return <NotFound />;
};
