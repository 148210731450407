import { v4 as uuidv4 } from 'uuid';
import create from 'zustand';

export interface Toast {
  id: string;
  title: string;
  description: string;
  type: 'error' | 'success' | 'info';
}

export interface Modal {
  title: string;
  content: React.ReactNode;
  withCancelButton: boolean;
  actions?: {
    label: string;
    action: () => void;
    isCTA?: boolean;
  }[];
}

interface NotificationsState {
  // State
  toasts: Toast[];
  modal: Modal | undefined;
  // Methods
  addToast: (toast: Omit<Toast, 'id'>) => void;
  showModal: (modal: Modal) => void;
  removeToast: (id: string) => void;
}

const NOTIFICATION_DURATION = 5000;

const defaultState = {
  toasts: [],
  modal: undefined,
};

export const useNotificationsStore = create<NotificationsState>()(set => ({
  // State
  ...defaultState,
  // Methods
  addToast: toast => set(state => addToast(state, toast)),
  showModal: modal => set(state => showModal(state, modal)),
  removeToast: id => set(state => removeToast(state, id)),
}));

const showModal = (state: NotificationsState, modal: Modal) => {
  return {
    ...state,
    modal,
  };
};

const addToast = (state: NotificationsState, newToast: Omit<Toast, 'id'>) => {
  const id = uuidv4();

  const toast: Toast = {
    id,
    ...newToast,
  };

  // If it's not an important message, try to auto remove the notification
  if (['info', 'success'].includes(newToast.type)) {
    setTimeout(() => {
      state.removeToast(id);
    }, NOTIFICATION_DURATION);
  }

  return {
    ...state,
    toasts: [...state.toasts, toast],
  };
};

const removeToast = (state: NotificationsState, id: string) => {
  return {
    ...state,
    toasts: state.toasts.filter(toast => toast.id !== id),
  };
};
