import { Link, MakeGenerics, useSearch } from '@tanstack/react-location';
import { FunctionComponent, useState } from 'react';

import ContactFrom from '../../components/forms/ContactForm';
import { Button } from '../../components/ui/Button';
import { Card } from '../../components/ui/Card';

type LocationGenerics = MakeGenerics<{
  Search: {
    from?: string;
  };
}>;

const Contact: FunctionComponent = () => {
  const search = useSearch<LocationGenerics>();

  const [messageSent, setMessageSent] = useState(false);

  const handleOnSubmit = (messageSent: boolean) => {
    setMessageSent(messageSent);
  };

  return (
    <div className="hero min-h-screen">
      <div className="hero-content flex-col gap-8 justify-center text-center">
        <div>
          <img src="/icon-horizontal.webp" alt="logo Eurika" />
        </div>

        {messageSent ? (
          <Card className="gap-4">
            <h1 className="prose prose-2xl text-primary font-bold">Message envoyé !</h1>
            <p className="text-neutral-content">
              Nous avons bien prit en compte votre message. Nous reviendrons vers vous dans les plus brefs délais.
            </p>
            {search.from !== undefined ? (
              <a href={search.from}>
                <Button interactive={false} color="primary">
                  Retour
                </Button>
              </a>
            ) : null}
          </Card>
        ) : (
          <Card>
            <h1 className="prose prose-2xl">Nous contacter</h1>
            <p className="text-neutral-content">
              N'hésitez pas à nous contacter afin de nous faire part de vos interrogations ou suggestions. Nous
              répondons à tous vos messages.
            </p>
            <ContactFrom onSubmit={handleOnSubmit} />
          </Card>
        )}

        <p>
          Aller sur la{' '}
          <Link to="/">
            <span className="link">page d'accueil</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Contact;
