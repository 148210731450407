import { forwardRef, HTMLInputTypeAttribute, ReactNode } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface Props {
  label: string;
  error?: FieldError;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  children?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextArea = forwardRef<HTMLTextAreaElement, Props & ReturnType<UseFormRegister<any>>>(
  ({ label, name, placeholder, required, onChange, onBlur, error, children }, ref) => {
    return (
      <div className="form-control">
        <label className="label">
          <span className="label-text">{label}</span>
          {required === true ? <span className="font-bold text-primary">*</span> : null}
          {children !== undefined ? <label className="label">{children}</label> : null}
        </label>
        <textarea
          className="textarea textarea-bordered bg-transparent"
          name={name}
          placeholder={placeholder}
          ref={ref}
          required={required}
          onChange={onChange}
          onBlur={onBlur}
        />
        {error ? (
          <label className="label">
            <span className="label-text-alt text-error">{error.message}</span>
          </label>
        ) : null}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
export default TextArea;
