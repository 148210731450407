import { GPSPoint } from '@eurika/core';
import { clamp, Err, Ok, Result } from '@eurika/utils';
import polyline from '@mapbox/polyline';
import axios from 'axios';

import {
  Address,
  MapboxDirectionResult,
  MapboxMapMatchingResult,
  MapboxMapMatchingSuccess,
  MapboxPlaceResult,
  MapboxReverseGeocodingResult,
} from '../models';

export class MapService {
  private static readonly REQUEST_TIMEOUT = 3000;
  // Minimum distance a point can be moved in order to match the road when using map matching (in meters)
  private static readonly MIN_MATCHING_RADIUS = 5;
  // Maximum value allowed by the MapBox API
  private static readonly MAX_MATCHING_RADIUS = 50;
  private static readonly contextKeys = ['country', 'region', 'postCode', 'place'] as const;

  static accessToken = '';

  static initialize(accessToken: string) {
    MapService.accessToken = accessToken;
  }

  static extractCoordinates(locations: GPSPoint[]): string {
    return locations.map(({ longitude, latitude }) => `${longitude},${latitude}`).join(';');
  }

  static extractContextData(
    features: MapboxPlaceResult['features'] | MapboxReverseGeocodingResult['features']
  ): Address[] {
    return features.map(feature => {
      // Keep all keys of `contextKeys` array in an object (Partial of Address)
      const contextData = feature.context.reduce((acc, curr) => {
        const contextKey = curr.id.split('.')[0] as typeof MapService.contextKeys[number];

        if (MapService.contextKeys.includes(contextKey)) {
          return { ...acc, [contextKey]: curr.text };
        }

        return acc;
      }, {} as Record<typeof MapService.contextKeys[number], string>);

      const fullObject: Address = {
        hasBeenFilled: true,
        fullName: feature.place_name,
        position: { latitude: feature.center[1], longitude: feature.center[0] },
        types: feature.place_type,
        street: feature.text,
        ...contextData,
      };

      if (feature.address !== undefined) {
        fullObject.address = feature.address;
      }

      return fullObject;
    });
  }

  static geometryToGPSPoints(geometry: string): GPSPoint[] {
    return polyline.decode(geometry).map(
      ([latitude, longitude]): GPSPoint => ({
        latitude,
        longitude,
      })
    );
  }

  // Takes a successful response from the Map Matching Mapbox API, and add the metadata from the locations to the correct points of the matched locations
  private static mapLocationsMetadataToMatching(
    matchingResult: MapboxMapMatchingSuccess,
    locations: GPSPoint[]
  ): GPSPoint[] {
    const matchedGeometries = matchingResult.matchings.map(matching =>
      MapService.geometryToGPSPoints(matching.geometry)
    );

    return matchingResult.tracepoints.reduce<GPSPoint[]>((matchedPoints, tracepoint, index) => {
      if (tracepoint !== null) {
        const { latitude, longitude } = matchedGeometries[tracepoint.matchings_index][tracepoint.waypoint_index];

        matchedPoints.push({
          ...locations[index],
          latitude,
          longitude,
        });
      }

      return matchedPoints;
    }, []);
  }

  /**
   * Takes a array of GPSPoints and matches it on a road. Returns the array of the projected points on the corresponding
   * road. It uses the Mapbox map matching API underneath (see https://docs.mapbox.com/api/navigation/map-matching). This
   * means that it has the same limitations, for example the input locations cannot contain more than a 100 values.
   */
  static async matchLocationsToRoad(locations: GPSPoint[]): Promise<Result<GPSPoint[], MapService.MatchingError>> {
    if (MapService.accessToken === '') {
      return Err({ code: MapService.MatchingErrorCodes.Forbidden, message: 'No access token given' });
    }
    const DRIVING_API = 'https://api.mapbox.com/matching/v5/mapbox/driving';

    let query = `${DRIVING_API}/${MapService.extractCoordinates(locations)}?access_token=${MapService.accessToken}`;
    const radiusesString = locations
      .map(({ accuracy }) => {
        if (accuracy === undefined) {
          return '';
        }
        const accuracyInMeters = accuracy * 1000;

        return clamp(accuracyInMeters, MapService.MIN_MATCHING_RADIUS, MapService.MAX_MATCHING_RADIUS).toString();
      })
      .join(';');
    query += `&radiuses=${radiusesString}`;
    if (locations.every(location => location.timestamp !== undefined)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      query += `&timestamps=${locations.map(location => (location.timestamp! / 1000).toFixed(0)).join(';')}`;
    }

    return (
      axios
        // It might be interesting to use the tidy option to remove clusters and outliers (see https://docs.mapbox.com/api/navigation/map-matching/#retrieve-a-match)
        .get<MapboxMapMatchingResult>(encodeURI(query), { timeout: MapService.REQUEST_TIMEOUT })
        .then(
          (response): Result<GPSPoint[], MapService.MatchingError> => {
            switch (response.data.code) {
              case 'NoMatch':
                return Err<GPSPoint[], MapService.MatchingError>({
                  code: MapService.MatchingErrorCodes.NoMatch,
                  message: response.data.message,
                });
              case 'NoSegment':
                return Err<GPSPoint[], MapService.MatchingError>({
                  code: MapService.MatchingErrorCodes.NoSegment,
                  message: response.data.message,
                });
              case 'Ok':
                return Ok(MapService.mapLocationsMetadataToMatching(response.data, locations));
              /* istanbul ignore next */
              default:
                console.warn('Should be unreachable');
                return Err<GPSPoint[], MapService.MatchingError>({
                  code: MapService.MatchingErrorCodes.NoMatch,
                  message: 'No match',
                });
            }
          },
          error =>
            Err<GPSPoint[], MapService.MatchingError>({
              code: error.response.data.code,
              message: error.response.data.message,
            })
        )
        .catch(() =>
          Err({
            code: MapService.MatchingErrorCodes.NoMatch,
            message: 'Could not find a match',
          })
        )
    );
  }

  /**
   * Takes a GPSPoint and tries to find the matching address. If successful, returns it as an Address object
   */
  static async getAddressFromPoint(point: GPSPoint): Promise<Address> {
    if (MapService.accessToken === '') {
      throw new Error('No access token given');
    }

    const GEOCODING_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    return axios
      .get<MapboxReverseGeocodingResult>(
        `${GEOCODING_API}/${MapService.extractCoordinates([point])}.json?access_token=${MapService.accessToken}`,
        { timeout: MapService.REQUEST_TIMEOUT }
      )
      .then(res => {
        const features = res.data.features;
        if (features.length === 0) {
          return {
            hasBeenFilled: true,
            position: point,
          };
        }

        const address = MapService.extractContextData([features[0]])[0];

        return address;
      })
      .catch(error => {
        // Log any error other than 'ERR_NETWORK' because it should not happen
        if (error.code !== 'ERR_NETWORK') {
          console.error(
            `Error while trying to get the address of '${point.latitude};${point.longitude}'\n'${
              error.code
            }': ${JSON.stringify(error)}`
          );
        }

        return {
          position: point,
          hasBeenFilled: false,
        };
      });
  }

  static async getDirection(
    start: GPSPoint,
    end: GPSPoint
  ): Promise<Result<{ distance: number; points: GPSPoint[] }, MapService.DirectionError>> {
    if (MapService.accessToken === '') {
      return Err({ code: MapService.DirectionErrorCode.Forbidden, message: 'No access token given' });
    }

    const DIRECTION_API = 'https://api.mapbox.com/directions/v5/mapbox/driving';
    const queryString = MapService.extractCoordinates([start, end]);

    return axios
      .get<MapboxDirectionResult>(`${DIRECTION_API}/${queryString}?access_token=${MapService.accessToken}`)
      .then(response => {
        switch (response.data.code) {
          case 'NoRoute':
            return Err({ code: MapService.DirectionErrorCode.NoRoute, message: response.data.message ?? 'NoRoute' });
          case 'NoSegment':
            return Err({
              code: MapService.DirectionErrorCode.NoSegment,
              message: response.data.message ?? 'NoSegment',
            });
          case 'Ok':
            const matching = response.data.routes[0];
            return Ok({
              points: polyline.decode(matching.geometry).map(
                ([latitude, longitude]): GPSPoint => ({
                  latitude,
                  longitude,
                })
              ),
              distance: matching.distance / 1000,
            });
          /* istanbul ignore next */
          default:
            console.warn('Should be unreachable');
            return Err({ code: MapService.DirectionErrorCode.NoRoute, message: 'No route' });
        }
      });
  }

  /**
   * Search addresses that match with the query sting
   * @param query place search
   * @param limit number of result expected (max 10)
   * @param country ISO 3166 alpha 2 code of country needed
   */
  static async autocomplete(
    query?: string,
    limit = 5,
    country = 'FR'
  ): Promise<Result<Address[], MapService.PlaceSearchError>> {
    if (MapService.accessToken === '') {
      return Err({ code: MapService.PlaceSearchErrorCode.Forbidden, message: 'No access token given' });
    }

    const PLACES_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    const queryString = encodeURI(query ?? '');

    // Restricted types of addresses
    const types = ['poi', 'address'];

    return axios
      .get<MapboxPlaceResult>(
        `${PLACES_API}/${queryString}.json?proximity=ip&access_token=${
          MapService.accessToken
        }&limit=${limit}&country=${country}&types=${types.join(',')}`
      )
      .then(result => Ok(MapService.extractContextData(result.data.features)));
  }
}

export namespace MapService {
  export type MatchingError = {
    code: MatchingErrorCodes;
    message: string;
  };

  export enum MatchingErrorCodes {
    NoMatch = 'NoMatch',
    NoSegment = 'NoSegment',
    Forbidden = 'Forbidden',
    TooManyCoordinates = 'TooManyCoordinates',
    ProfileNotFound = 'ProfileNotFound',
    InvalidInput = 'InvalidInput',
  }

  export type ReverseGeocodingError = {
    code: ReverseGeocodingErrorCode;
    message: string;
  };

  export enum ReverseGeocodingErrorCode {
    NoConnection = 'NoConnection',
    NoMatch = 'NoMatch',
    Forbidden = 'Forbidden',
  }

  export type PlaceSearchError = {
    code: PlaceSearchErrorCode;
    message: string;
  };

  export enum PlaceSearchErrorCode {
    Forbidden = 'Forbidden',
  }

  export type DirectionError = {
    code: DirectionErrorCode;
    message: string;
  };

  export enum DirectionErrorCode {
    Forbidden = 'Forbidden',
    NoRoute = 'NoRoute',
    NoSegment = 'NoSegment',
    ProfileNotFound = 'ProfileNotFound',
    InvalidInput = 'InvalidInput',
  }
}
