import { AuthenticationService } from '@eurika/core';
import { Navigate } from '@tanstack/react-location';
import { FunctionComponent, useEffect, useState } from 'react';

import { LoaderPage } from '../components/ui/Loader';

type AuthGuardProps = {
  loading?: React.ReactElement;
  notAllowed?: React.ReactElement;
  children?: React.ReactNode;
  redirect?: string;
};

/**
 * Wrapper Component to check authentication
 * @param AuthGuardProps
 * @returns
 */
export const AuthGuard: FunctionComponent<AuthGuardProps> = ({ children, redirect }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(AuthenticationService.isAuthenticated());

  useEffect(() => {
    setIsLoading(true);

    AuthenticationService.signInCheck()
      .then(status => {
        setIsAuthenticated(status);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LoaderPage />;
  }

  return <>{isAuthenticated ? children : <Navigate to="/connexion" search={{ redirect: redirect }} />}</>;
};
