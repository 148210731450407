import { CloudFetchService, UserPayload } from '@eurika/core';
import { Check as CheckIcon, User as UserIcon } from 'feather-icons-react';
import { FunctionComponent, useState } from 'react';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

interface Props {
  user: UserPayload;
  isVerified: boolean;
}

export const UserCard: FunctionComponent<Props> = ({ user, isVerified }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const confirmationSendTimeout = 5000;
  const [confirmationSent, setConfirmationSent] = useState(false);

  const handleConfirmationClick = async () => {
    setConfirmationSent(true);

    await CloudFetchService.resendVerificationEmail({ userId: user.ref.id });
    addToast({
      type: 'info',
      title: "Vérification de l'adresse mail",
      description: `Un mail à été envoyé à l'adresse ${user.email}, aller consulter votre messagerie pour continuer.`,
    });

    // Wait `confirmationSendTimeout` ms to allow mail reset
    setTimeout(() => {
      setConfirmationSent(false);
    }, confirmationSendTimeout);
  };

  return (
    <Card className="lg:flex-row gap-4 items-center justify-between">
      <div className="flex flex-row gap-4 items-start">
        <div className="avatar placeholder">
          <div className="bg-neutral text-neutral-content rounded-full w-16">
            <UserIcon />
          </div>
        </div>

        <div className="flex flex-col">
          <h1 className="prose-2xl m-0">
            {user.firstName} {user.lastName}
          </h1>
          <p className="prose-sm m-0">{user.email}</p>
        </div>
      </div>

      {isVerified === true ? (
        <div className="flex flex-row gap-2 items-center">
          <CheckIcon className="text-primary" />
          <span className="font-bold text-primary">Compte vérifié</span>
        </div>
      ) : (
        <Button color="primary" isDisabled={confirmationSent} onClick={handleConfirmationClick}>
          Vérifier mon email
        </Button>
      )}
    </Card>
  );
};
