import { Outlet } from '@tanstack/react-location';
import { FC } from 'react';

const Layout: FC = () => {
  return (
    <div className="max-w-screen-md mx-auto">
      <Outlet />;
    </div>
  );
};

export default Layout;
