import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import { FunctionComponent, useState } from 'react';

import { AccountCreationForm } from '../../components/forms/AccountCreationForm';
import { LoginForm } from '../../components/forms/LoginForm';
import { Card } from '../../components/ui/Card';

type Tab = 'connection' | 'createAccount';

type LocationGenerics = MakeGenerics<{
  Search: {
    tab?: Tab;
    redirect?: string;
  };
}>;

const Login: FunctionComponent = () => {
  const navigate = useNavigate();
  const search = useSearch<LocationGenerics>();

  const [selectedTab, setSelectedTab] = useState<Tab>(search.tab || 'connection');

  const handleSelectTab = (tab: Tab) => {
    setSelectedTab(tab);
  };

  const handleConnection = (isConnected: boolean) => {
    const redirectPath = search.redirect ?? '/';

    if (isConnected) {
      navigate({ to: redirectPath });
    }
  };

  const handleAccountCreation = (isConnected: boolean) => {
    const redirectPath = search.redirect ?? '/';

    if (isConnected) {
      navigate({ to: redirectPath });
    }
  };

  return (
    <div className="hero min-h-screen">
      <div className="flex flex-col gap-8 justify-center md:w-1/2 w-11/12">
        <div className="w-full">
          <img className="mx-auto" src="/icon-horizontal.webp" alt="logo eurika" />
        </div>
        <Card>
          <div className="tabs tabs-boxed mb-4">
            <a
              className={`tab flex-1 tab-lg ${selectedTab === 'connection' ? 'tab-active' : ''}`}
              onClick={() => {
                handleSelectTab('connection');
              }}
            >
              Connexion
            </a>
            <a
              className={`tab flex-1 tab-lg flex-nowrap leading-5 whitespace-nowrap ${
                selectedTab === 'createAccount' ? 'tab-active' : ''
              }`}
              onClick={() => {
                handleSelectTab('createAccount');
              }}
            >
              Créer un compte
            </a>
          </div>

          {selectedTab === 'connection' ? (
            <LoginForm onSubmit={handleConnection} />
          ) : (
            <AccountCreationForm onSubmit={handleAccountCreation} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default Login;
