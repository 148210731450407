import { Link, MakeGenerics, useSearch } from '@tanstack/react-location';
import { FunctionComponent } from 'react';

import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import { Card } from '../../components/ui/Card';

type LocationGenerics = MakeGenerics<{
  Search: {
    oobCode?: string;
  };
}>;

const ResetPassword: FunctionComponent = () => {
  const search = useSearch<LocationGenerics>();

  return (
    <div className="hero min-h-screen">
      <div className="hero-content flex-col gap-8 justify-center text-center">
        <div>
          <img src="/icon-horizontal.webp" alt="logo Eurika" />
        </div>

        <Card>
          <h1 className="prose prose-2xl">Réinitialisation du mot de passe</h1>
          <ResetPasswordForm oobCode={search.oobCode} />
        </Card>

        <p>
          Aller sur la{' '}
          <Link to="/">
            <span className="link">page d'accueil</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
