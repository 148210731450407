import FeatherIcon from 'feather-icons-react';
import React, { FC } from 'react';

interface Props {
  className?: string;
  color?: 'error' | 'success' | 'primary';
  style?: 'outline' | 'ghost' | 'link';
  iconName?: string;
  iconPosition?: 'before' | 'after';
  type?: 'button' | 'submit' | 'reset';
  interactive?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltipText?: string;
  children?: React.ReactNode;
}

export const Button: FC<Props> = ({
  style,
  color,
  className,
  iconName,
  iconPosition = 'before',
  type = 'button',
  onClick,
  isLoading = false,
  isDisabled = false,
  tooltipText,
  children,
}) => {
  const styleClass = style
    ? {
        outline: 'btn-outline',
        ghost: 'btn-ghost',
        link: 'btn-link',
      }[style]
    : undefined;

  const colorClass = color
    ? {
        error: 'btn-error',
        success: 'btn-success',
        primary: 'btn-primary',
      }[color]
    : undefined;

  return (
    <button
      type={type}
      data-tip={tooltipText}
      disabled={isDisabled || isLoading}
      className={`
        btn ${styleClass} ${colorClass}
        gap-4 min-w-[44px]
        ${tooltipText !== undefined ? 'tooltip' : ''}
        ${isLoading ? 'loading' : ''}
        ${className}
      `}
      onClick={onClick}
    >
      {iconName !== undefined && iconPosition === 'before' ? <FeatherIcon icon={iconName} /> : null}
      {children !== undefined ? <span className="flex-1">{children}</span> : null}
      {iconName !== undefined && iconPosition === 'after' ? <FeatherIcon icon={iconName} /> : null}
    </button>
  );
};
