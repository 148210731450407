import { AuthenticationService, emailValidator, passwordValidator } from '@eurika/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { queryClient } from '../../main';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import { TextInput } from './inputs/TextInput';

interface Props {
  onSubmit?: (isConnected: boolean) => void;
}

const LoginFormDataSchema = yup
  .object({
    email: emailValidator,
    password: passwordValidator,
  })
  .required();

export type LoginFormData = yup.InferType<typeof LoginFormDataSchema>;

export const LoginForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormDataSchema),
  });

  const handleFormSubmit = async (formData: LoginFormData) => {
    // Remove query cached data
    queryClient.clear();

    const { email, password } = formData;
    const loginResponse = await AuthenticationService.loginWithEmailPassword(email, password);

    if (loginResponse.isErr()) {
      let errorMessage;

      switch (loginResponse.unwrapErr().code) {
        case 'auth/user-not-found':
          errorMessage =
            'Cette adresse mail ne correspond à aucun utilisateur existant, veuillez vérifier vos informations ou créer un nouveau compte';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Mot de passe invalide';
          break;
        case 'auth/invalid-email':
          // Should not be possible because the form checks the email validity
          errorMessage = 'Adresse mail invalide';
          break;
        case 'auth/user-disabled':
          errorMessage = 'Compte désactivé';
          break;
        default:
          errorMessage = 'Erreur inconnue, veuillez contacter le support ou réessayer plus tard';
          break;
      }

      addToast({ type: 'error', title: 'Erreur de connexion', description: errorMessage });
    }

    const isConnected = loginResponse.isOk();

    onSubmit?.(isConnected);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextInput {...register('email')} error={errors.email} label="Adresse mail" />
      <TextInput {...register('password')} error={errors.password} label="Mot de passe" type="password">
        {/* <a href="/mot-de-passe-oublie" className="label-text-alt link link-hover">
          Mot de passe oublié ?
        </a> */}
      </TextInput>

      <div className="form-control mt-6">
        <Button color="primary" type="submit" isLoading={isSubmitting}>
          Me connecter
        </Button>
      </div>
    </form>
  );
};
