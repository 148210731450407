import { useNavigate } from '@tanstack/react-location';
import { FC } from 'react';

import { Button } from '../../components/ui/Button';

const NotFound: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="hero min-h-screen">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">404: Oups...</h1>
          <p className="py-6">Cette page n'existe pas</p>
          <Button color="primary" onClick={() => navigate({ to: '/' })}>
            Retourner à la page d'accueil
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
