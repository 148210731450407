import type { CollectionReference } from 'firebase/firestore';
import { collection } from 'firebase/firestore';

import {
  BetaAccessRequest,
  BetaAccessRequestRef,
  Contact,
  ContactRef,
  NewsletterRequest,
  NewsletterRequestRef,
  WithoutDatabaseEntry,
} from '../models';
import { DatabaseEntryBuilder } from './DatabaseEntryBuilder';
import { DatabaseService, getFirestoreDataConverter } from './DatabaseService';

export class ContactsDatabaseService {
  private static readonly CONTACT_COLLECTION = 'contacts';
  private static readonly NEWSLETTER_REQUEST_COLLECTION = 'newsletter_requests';
  private static readonly BETA_ACCESS_REQUEST_COLLECTION = 'beta_access_requests';

  private static getContactCollection(): CollectionReference<Contact> {
    const db = DatabaseService.getDatabase();

    return collection(db, ContactsDatabaseService.CONTACT_COLLECTION).withConverter(
      getFirestoreDataConverter<Contact>()
    );
  }

  private static getNewsletterRequestCollection(): CollectionReference<NewsletterRequest> {
    const db = DatabaseService.getDatabase();

    return collection(db, ContactsDatabaseService.NEWSLETTER_REQUEST_COLLECTION).withConverter(
      getFirestoreDataConverter<NewsletterRequest>()
    );
  }

  private static getBetaAccessRequestCollection(): CollectionReference<BetaAccessRequest> {
    const db = DatabaseService.getDatabase();

    return collection(db, ContactsDatabaseService.BETA_ACCESS_REQUEST_COLLECTION).withConverter(
      getFirestoreDataConverter<BetaAccessRequest>()
    );
  }

  static async createContact(contact: WithoutDatabaseEntry<Contact>): Promise<ContactRef> {
    return DatabaseEntryBuilder.create(contact)
      .withGeneratedCreatedAt()
      .insert(ContactsDatabaseService.getContactCollection())
      .then(res => res.unwrap());
  }

  static async createNewsletterRequest(
    newsletterRequest: WithoutDatabaseEntry<NewsletterRequest>
  ): Promise<NewsletterRequestRef> {
    return DatabaseEntryBuilder.create(newsletterRequest)
      .withGeneratedCreatedAt()
      .insert(ContactsDatabaseService.getNewsletterRequestCollection())
      .then(res => res.unwrap());
  }

  static async createBetaAccessRequest(
    testContact: WithoutDatabaseEntry<BetaAccessRequest>
  ): Promise<BetaAccessRequestRef> {
    return DatabaseEntryBuilder.create(testContact)
      .withGeneratedCreatedAt()
      .insert(ContactsDatabaseService.getBetaAccessRequestCollection())
      .then(res => res.unwrap());
  }
}
