import './index.css';

import { AuthenticationService, CloudFetchService, CloudFunctionService, DatabaseService } from '@eurika/core';
import * as Sentry from '@sentry/react';
import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { ModalContainer } from './components/ui/Modals';
import { ToastsContainer } from './components/ui/Toasts';
import { routes } from './pages/index.route';

// Init Firebase connection
const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
DatabaseService.initializeDatabase(firebaseApp);
AuthenticationService.initializeAuth(getAuth());
CloudFunctionService.initializeFunctions(firebaseApp);

CloudFetchService.setDomain(import.meta.env.VITE_API_DOMAIN || '');

// Connect to emulator on all env bu PROD one
if (!import.meta.env.PROD && import.meta.env.VITE_USE_EMULATOR === '1') {
  // Set this env variable to use the emulator on device in the same local network
  const host = import.meta.env.VITE_HOST || 'localhost';

  DatabaseService.initEmulator(host);
  AuthenticationService.initEmulator(host, true);
  CloudFunctionService.initEmulator(host);
}

// Init query store
export const queryClient = new QueryClient();

const App = () => {
  const location = new ReactLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <Router location={location} routes={routes}>
        <Outlet />
        <ToastsContainer />
        <ModalContainer />
      </Router>
      {!import.meta.env.PROD ? <ReactQueryDevtools position="bottom-right" /> : null}
    </QueryClientProvider>
  );
};

Sentry.init({
  dsn: 'https://6ead66b302a342e246de616533667688@o1301805.ingest.sentry.io/4506304144277504',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', import.meta.env.VITE_API_DOMAIN],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
if (!container?.innerHTML) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
