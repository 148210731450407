import { AuthenticationService, CloudFetchService, UserEntitlement } from '@eurika/core';
import { Link } from '@tanstack/react-location';
import { FunctionComponent, useState } from 'react';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { createCustomerPortal } from '../../utils/payment/subscription';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';

type Props = {
  entitlement: UserEntitlement | undefined;
};

export const SubscriptionCard: FunctionComponent<Props> = ({ entitlement }) => {
  const [isLoading, setIsLoading] = useState(false);
  const showModal = useNotificationsStore(state => state.showModal);

  const userEntitlement = entitlement;
  const subscriptionExpired = userEntitlement?.expired ?? false;

  const hasStandardEntitlement = userEntitlement?.name === 'standard' && subscriptionExpired === false;

  const subscriptionName = subscriptionExpired ? 'Gratuit' : userEntitlement?.displayName ?? 'Gratuit';
  const subscriptionTagStyle = hasStandardEntitlement
    ? 'text-white bg-primary'
    : 'text-primary bg-primary bg-opacity-10';

  const handleManageSubscription = async () => {
    setIsLoading(true);

    const userId = AuthenticationService.getAuthenticatedUser()?.uid;
    if (userId !== undefined) {
      const entitlementOwnerData = await CloudFetchService.getUserEntitlementOwner({ userId }).catch(() => undefined);

      if (entitlementOwnerData?.owner.sameOwner === false) {
        showModal({
          title: "Gestion de l'abonnement",
          content: (
            <p>
              Votre abonnement est géré par "
              <a className="underline font-bold" href={`mailto:${entitlementOwnerData.owner.email}`}>
                {entitlementOwnerData.owner.email}
              </a>
              ". Contactez le pour gérer votre abonnement.
            </p>
          ),
          withCancelButton: false,
        });
      } else {
        if (entitlement?.purchasedStore === 'app_store') {
          const APPLE_SUBSCRIPTION_REDIRECT_URL = 'https://apps.apple.com/account/subscriptions';
          window.open(APPLE_SUBSCRIPTION_REDIRECT_URL, '_blank');
        } else {
          await createCustomerPortal();
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <Card className="gap-4">
      <div className="flex items-center justify-center gap-2 lg:justify-start">
        <span className={`inline-block text-base p-2 rounded-md font-bold uppercase ${subscriptionTagStyle}`}>
          {subscriptionName}
        </span>
      </div>

      <div className="flex flex-col items-start gap-2">
        <p className="my-0">
          Vous utilisez l'abonnement <span className="badge badge-primary">{subscriptionName}</span> d'Eurika
        </p>
      </div>

      <footer>
        {hasStandardEntitlement ? (
          <Button onClick={handleManageSubscription} isLoading={isLoading}>
            Gérer mon abonnement
          </Button>
        ) : (
          <Link to="/mon-compte/selection-abonnement">
            <Button>Passer à l'abonnement standard</Button>
          </Link>
        )}
      </footer>
    </Card>
  );
};
