import { AuthenticationService, CloudFetchService, emailValidator, UserEntitlementsAttributions } from '@eurika/core';
import { formatDate } from '@eurika/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from '@tanstack/react-location';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import TextInput from '../../components/forms/inputs/TextInput';
import { Button } from '../../components/ui/Button';
import { SubscriptionStatusBadge } from '../../components/ui/SubscriptionStatusBadge';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { createCheckoutSession, createCustomerPortal } from '../../utils/payment/subscription';

const FormDataSchema = yup.object({ email: emailValidator }).required();

export type FormData = yup.InferType<typeof FormDataSchema>;

const SubscriptionManagementPage: FC = () => {
  const navigate = useNavigate();
  const showModal = useNotificationsStore(state => state.showModal);
  const addToast = useNotificationsStore(state => state.addToast);

  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [attributions, setAttributions] = useState<UserEntitlementsAttributions['attributions']>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormDataSchema),
  });

  const handleFormSubmit = async (data: FormData) => {
    setSearchIsLoading(true);

    const userEntitlement = await CloudFetchService.getUserEntitlementByEmail({ email: data.email }).catch(err => {
      switch (err?.code) {
        case 400:
          addToast({
            type: 'error',
            title: 'Erreur',
            description: 'Le texte saisi ne correspond pas à une adresse mail valide',
          });
          break;
        case 404:
          addToast({
            type: 'error',
            title: 'Erreur',
            description: "Aucun utilisateur n'a été trouvé avec cette adresse mail",
          });
          break;
        default:
          addToast({
            type: 'error',
            title: 'Erreur',
            description: 'Une erreur est survenue, veuillez réessayer plus tard',
          });
          break;
      }

      return undefined;
    });

    setSearchIsLoading(false);

    if (userEntitlement === undefined) {
      return;
    }

    if (userEntitlement.entitlement.name === 'guest') {
      addToast({
        type: 'error',
        title: 'Erreur',
        description:
          "L'adresse mail de ce compte n'a pas été vérifiée. Contactez la personne concernée pour lui demander de valider son adresse mail",
      });
      return;
    }

    showModal({
      title: 'Souscription',
      content: `Voulez-vous souscrire à un abonnement standard pour le compte "${data.email}" ?`,
      withCancelButton: true,
      actions: [
        {
          label: 'Prendre un abonnement',
          isCTA: true,
          action: async () => {
            const targetedUserId = userEntitlement.userId;
            const targetedUserEmail = data.email;
            const userId = AuthenticationService.getAuthenticatedUser()?.uid;
            const userEmail = AuthenticationService.getAuthenticatedUser()?.email ?? undefined;

            if (userId !== undefined && userEmail !== undefined) {
              await createCheckoutSession('standard', userId, userEmail, targetedUserId, targetedUserEmail);
            }
          },
        },
      ],
    });
  };

  const handleBackButton = () => {
    navigate({ to: '/mon-compte' });
  };

  const handleSubscriptionsManagement = async () => {
    setIsLoading(true);
    await createCustomerPortal().catch(() => undefined);
    setIsLoading(false);
  };

  useEffect(() => {
    const ownerId = AuthenticationService.getAuthenticatedUser()?.uid;

    if (ownerId !== undefined) {
      CloudFetchService.getUserEntitlementAttributionOwnedBy({
        userId: ownerId,
      })
        .then(res => {
          setAttributions(res.attributions);
        })
        .catch(() => undefined);
    }
  }, []);

  return (
    <div className="flex flex-col gap-8">
      <Button style="ghost" iconName="chevron-left" className="self-start" onClick={handleBackButton}>
        Retour
      </Button>

      <section>
        <h3 className="mt-0">Liste des utilisateurs dont vous gérez l'abonnement</h3>
        <div className="bg-white rounded-xl border border-neutral px-8 py-4">
          <h4>Prendre un abonnement pour un utilisateur</h4>
          <div className="flex flex-col gap-4">
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleFormSubmit)}>
              <TextInput
                className="flex-1"
                {...register('email')}
                error={errors.email}
                label="Email"
                placeholder="Entrer l'email d'un compte existant"
              />
              <Button type="submit" color="primary" isLoading={searchIsLoading}>
                Ajouter un abonnement
              </Button>
            </form>
          </div>

          {attributions.length > 0 ? (
            <>
              <h4 className="">Gestion</h4>
              <div className="my-4">
                <Button iconName="external-link" onClick={handleSubscriptionsManagement} isLoading={isLoading}>
                  Gérer les abonnements
                </Button>
              </div>

              <h4 className="">Statut des abonnements actuels</h4>
              <div className="overflow-x-auto">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th>Utilisateur</th>
                      <th>Début de l'abonnement</th>
                      <th>Période de l'abonnement en cours</th>
                      <th>Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attributions.map(attribution => (
                      <tr key={attribution.targetedUserAppId}>
                        <td>{attribution.targetedUserAppEmail}</td>
                        <td>{formatDate(new Date(attribution.startAt), 'DD MMMM YYYY')}</td>
                        <td>
                          du <strong>{formatDate(new Date(attribution.currentPeriod.start), 'DD MMM YYYY')}</strong> au{' '}
                          <strong>{formatDate(new Date(attribution.currentPeriod.end), 'DD MMM YYYY')}</strong>
                        </td>
                        <td>
                          <SubscriptionStatusBadge status={attribution.status} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
        </div>
      </section>
    </div>
  );
};

export default SubscriptionManagementPage;
