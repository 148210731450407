import { FC } from 'react';

import { Toast, useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from './Button';

export const ToastCard: FC<{ toast: Toast }> = ({ toast }) => {
  const removeToast = useNotificationsStore(state => state.removeToast);
  const typeClass = { info: 'alert-info', error: 'alert-error', success: 'alert-success' }[toast.type];

  return (
    <div className="toast toast-end md:w-1/2 w-full transition-all">
      <div className={`alert ${typeClass} items-start`}>
        <div className="flex flex-col justify-start items-start">
          <h3 className="prose-xl font-bold capitalize">{toast.title}</h3>
          <p>{toast.description}</p>
        </div>
        <Button style="ghost" iconName="x" onClick={() => removeToast(toast.id)} />
      </div>
    </div>
  );
};

export const ToastsContainer = () => {
  const toasts = useNotificationsStore(state => state.toasts);

  return (
    <div className="absolute stack" style={{ zIndex: 100 }}>
      {toasts.map(toast => (
        <ToastCard key={toast.id} toast={toast} />
      ))}
    </div>
  );
};
