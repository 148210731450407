import { AuthenticationService, CloudFetchService } from '@eurika/core';
import { MakeGenerics, useMatch, useNavigate } from '@tanstack/react-location';
import { FC, useState } from 'react';

import { SubscriptionCard } from '../../components/card/SubscriptionCard';
import { UserCard } from '../../components/card/UserCard';
import { Button } from '../../components/ui/Button';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { CustomUserLoaderData } from './_route';

const AccountPage: FC = () => {
  const {
    data: { user, entitlement },
  } = useMatch<MakeGenerics<{ LoaderData: CustomUserLoaderData }>>();

  const navigate = useNavigate();
  const addToast = useNotificationsStore(state => state.addToast);
  const showModal = useNotificationsStore(state => state.showModal);

  const authenticatedUser = AuthenticationService.getAuthenticatedUser();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleAccountDelete = async () => {
    const hasOngoingSubscription = entitlement?.name === 'standard' && entitlement.expired === false;

    if (hasOngoingSubscription) {
      addToast({
        type: 'error',
        title: 'Suppression du compte',
        description:
          "Il semble que vous avez un abonnement en cours. Si vous voulez supprimer votre compte, veuillez résilier votre abonnement et attendre la fin de la période d'abonnement en cours.",
      });
      return;
    }

    showModal({
      title: 'Suppression du compte',
      content: 'Est vous certain de vouloir supprimer votre compte et toutes ces informations ?',
      withCancelButton: true,
      actions: [
        {
          label: 'Tout supprimer',
          isCTA: true,
          action: async () => {
            if (authenticatedUser?.uid !== undefined) {
              setIsDeleting(true);
              await CloudFetchService.deleteAccount({ userId: authenticatedUser.uid });
              setIsDeleting(false);
            }

            navigate({ to: '/connexion' });
            addToast({
              type: 'success',
              title: 'Suppression du compte',
              description: 'Votre compte à bien été supprimé',
            });
          },
        },
      ],
    });
  };

  const handleSubscriptionManagement = () => {
    navigate({ to: '/mon-compte/gestion-abonnement' });
  };

  return authenticatedUser?.uid !== undefined ? (
    <div className="flex flex-col gap-4">
      {user ? <UserCard user={user} isVerified={authenticatedUser.emailVerified === true} /> : null}

      <div className="flex flex-col lg:flex-row gap-4">
        <section className="flex flex-col lg:items-start">
          <h3 className="mt-0">Abonnement</h3>
          <SubscriptionCard entitlement={entitlement} />
        </section>

        <section>
          <h3 className="mt-0">Actions</h3>

          <div className="flex flex-col gap-2">
            <Button iconName="users" onClick={handleSubscriptionManagement}>
              Gérer votre équipe
            </Button>
            <Button iconName="trash" color="error" style="outline" onClick={handleAccountDelete} isLoading={isDeleting}>
              Supprimer mon compte
            </Button>
          </div>
        </section>
      </div>
    </div>
  ) : null;
};

export default AccountPage;
