import { AuthenticationService } from '@eurika/core';
import { Link, MakeGenerics, useSearch } from '@tanstack/react-location';
import { Check as CheckIcon } from 'feather-icons-react';
import { FunctionComponent, useEffect, useState } from 'react';

import { Card } from '../../components/ui/Card';
import { useNotificationsStore } from '../../stores/notificationsStore';

type LocationGenerics = MakeGenerics<{
  Search: {
    oobCode?: string;
  };
}>;

const EmailVerify: FunctionComponent = () => {
  const addToast = useNotificationsStore(state => state.addToast);
  const search = useSearch<LocationGenerics>();

  const [isVerified, setIsVerified] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    if (isVerified === undefined) {
      const oobCode = search.oobCode;

      if (oobCode === undefined || typeof oobCode !== 'string') {
        addToast({
          type: 'error',
          title: "Confirmation de l'adresse mail",
          description: 'Le lien de réinitialisation est invalide.',
        });
        return;
      }

      AuthenticationService.confirmMail(oobCode)
        .then(() => {
          setIsVerified(true);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: "Confirmation de l'adresse mail",
            description: "Une erreur s'est produite lors de la confirmation de mail. Veuillez réessayer plus tard.",
          });
          setIsVerified(false);
        });
    }
  }, [search.oobCode, addToast, isVerified]);

  return (
    <div className="hero min-h-screen">
      <div className="hero-content flex-col gap-8 justify-center text-center">
        <div>
          <img src="/icon-horizontal.webp" alt="logo Eurika" />
        </div>

        <Card>
          <h1 className="prose prose-2xl">Vérification de l'adresse mail</h1>

          <div className="text-primary font-bold flex justify-center items-center gap-4">
            {isVerified === undefined ? (
              <span>En cours de vérification</span>
            ) : isVerified ? (
              <div>
                <div className="flex justify-center items-center gap-4 mb-4">
                  <CheckIcon /> <span>Compte vérifié</span>
                </div>
                <p className="text-neutral-content text-sm font-normal">
                  Veuillez vous déconnecter puis reconnecter sur l'application pour mettre à jour vos informations.
                </p>
              </div>
            ) : (
              <span>Erreur lors de la vérification !</span>
            )}
          </div>
        </Card>

        <p>
          Aller sur la{' '}
          <Link to="/">
            <span className="link">page d'accueil</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default EmailVerify;
