import { forwardRef, ReactNode } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';

interface Props {
  label: string;
  error?: FieldError;
  children?: ReactNode;
  options: { label: string; value: string }[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Radio = forwardRef<HTMLInputElement, Props & ReturnType<UseFormRegister<any>>>(
  ({ label, name, options, required, onChange, onBlur, error, children }, ref) => {
    return (
      <div className="form-control">
        <label className="label">
          <span className="label-text">{label}</span>
          {required === true ? <span className="font-bold text-primary">*</span> : null}
          {children !== undefined ? <label className="label">{children}</label> : null}
        </label>

        {options.map((option, index) => (
          <label className="label cursor-pointer" key={index}>
            <span className="label-text">{option.label}</span>
            <input
              type="radio"
              name={name}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              value={option.value}
              className="radio checked:bg-primary"
            />
          </label>
        ))}

        {error ? (
          <label className="label">
            <span className="label-text-alt text-error">{error.message}</span>
          </label>
        ) : null}
      </div>
    );
  }
);

Radio.displayName = 'Radio';
export default Radio;
