import { Err, Ok, Result } from '@eurika/utils';
import {
  applyActionCode,
  Auth,
  AuthError,
  confirmPasswordReset,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  Unsubscribe,
  updateEmail,
  updatePassword,
  User,
} from 'firebase/auth';

import { CloudFetchService } from './CloudFetchService';

export class AuthenticationService {
  private static auth?: Auth;

  private static getFirebaseAuth(): Auth {
    if (AuthenticationService.auth !== undefined) {
      return AuthenticationService.auth;
    } else {
      throw new Error('Authentication not initialized ');
    }
  }

  static initializeAuth(auth?: Auth): void {
    if (auth) {
      AuthenticationService.auth = auth;
    } else {
      const firebaseAuth = getAuth();
      firebaseAuth.languageCode = 'fr';

      AuthenticationService.auth = firebaseAuth;
    }
  }

  static initEmulator(host = 'localhost', noWarning = false): void {
    const auth = AuthenticationService.getFirebaseAuth();
    connectAuthEmulator(auth, `http://${host}:9099`, { disableWarnings: noWarning });
  }

  static async loginWithEmailPassword(email: string, password: string): Promise<Result<User, AuthError>> {
    const auth = AuthenticationService.getFirebaseAuth();

    return signInWithEmailAndPassword(auth, email.trim(), password)
      .then(result => Ok(result.user))
      .catch((error: AuthError) => Err(error));
  }
  static async loginWithCustomToken(token: string) {
    const auth = AuthenticationService.getFirebaseAuth();
    return signInWithCustomToken(auth, token);
  }

  static async resetPassword(oobCode: string, newPassword: string): Promise<void> {
    const auth = AuthenticationService.getFirebaseAuth();

    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  static async updateUserData(newEmail: string, newPassword: string): Promise<void> {
    const auth = AuthenticationService.getFirebaseAuth();
    const currentUser = auth.currentUser;

    if (currentUser === null) {
      throw new Error('There is no current User');
    }

    await updateEmail(currentUser, newEmail);
    await updatePassword(currentUser, newPassword);
  }

  static async confirmMail(oobCode: string): Promise<void> {
    const auth = AuthenticationService.getFirebaseAuth();

    await applyActionCode(auth, oobCode);
    return CloudFetchService.verifyEmail({ oobCode });
  }

  static getAuthenticatedUser(): User | undefined {
    const auth = AuthenticationService.getFirebaseAuth();
    return auth.currentUser ?? undefined;
  }

  static isAuthenticated(): boolean {
    const auth = AuthenticationService.getFirebaseAuth();
    return !!auth.currentUser;
  }

  static async reload(forceRefresh = true) {
    const auth = AuthenticationService.getFirebaseAuth();

    await auth.currentUser?.getIdToken(forceRefresh);
    return auth.currentUser?.reload();
  }

  static async signInCheck() {
    const auth = AuthenticationService.getFirebaseAuth();

    let unsubscribe: Unsubscribe | undefined = undefined;

    return new Promise((resolve, reject) => {
      unsubscribe = onIdTokenChanged(auth, resolve, reject);
    })
      .then(async user => (user as User).reload())
      .then(() => AuthenticationService.isAuthenticated())
      .catch(() => false)
      .finally(() => {
        unsubscribe?.();
      });
  }

  static logout(): Promise<void> {
    const auth = AuthenticationService.getFirebaseAuth();

    return signOut(auth);
  }

  static watchUserAuthStateChange(callback: (user: User | null) => void) {
    const auth = AuthenticationService.getFirebaseAuth();

    return onAuthStateChanged(auth, user => {
      callback(user);
    });
  }
}
