import { AuthenticationService, passwordValidator } from '@eurika/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import TextInput from './inputs/TextInput';

const ResetPasswordFormDataSchema = yup.object({ password: passwordValidator }).required();

export type ResetPasswordFormData = yup.InferType<typeof ResetPasswordFormDataSchema>;

interface Props {
  oobCode?: string;
}

const ResetPasswordForm: FunctionComponent<Props> = ({ oobCode }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(ResetPasswordFormDataSchema),
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = async (formData: ResetPasswordFormData) => {
    const newPassword = formData.password;

    if (oobCode === undefined || typeof oobCode !== 'string') {
      addToast({
        type: 'error',
        title: 'Réinitialisation du mot de passe',
        description: 'Le lien de réinitialisation est invalide.',
      });
      return;
    }

    await AuthenticationService.resetPassword(oobCode, newPassword)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Réinitialisation du mot de passe',
          description: `Votre mot de passe a été changé avec succès.`,
        });

        // In the future, this should redirect the user to the dashboard page
        setIsDisabled(true);
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Réinitialisation du mot de passe',
          description: `Une erreur s'est produite lors de la réinitialisation du mot de passe. Veuillez réessayer plus tard.`,
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextInput {...register('password')} error={errors.password} label="Nouveau mot de passe" type="password" />

      <div className="form-control mt-6">
        <Button color="primary" type="submit" isLoading={isSubmitting} isDisabled={isDisabled}>
          Mettre à jour
        </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
