import { AuthenticationService } from '@eurika/core';
import { MakeGenerics, Navigate, useMatch } from '@tanstack/react-location';
import { FC, useState } from 'react';

import { Button } from '../../components/ui/Button';
import { Card } from '../../components/ui/Card';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { createCheckoutSession } from '../../utils/payment/subscription';
import { CustomUserLoaderData } from './_route';

const SubscriptionSelectionPage: FC = () => {
  const {
    data: { entitlement },
  } = useMatch<MakeGenerics<{ LoaderData: CustomUserLoaderData }>>();

  const [isLoading, setIsLoading] = useState(false);
  const addToast = useNotificationsStore(state => state.addToast);

  if (entitlement && entitlement.expired === false && entitlement.name === 'standard') {
    addToast({ type: 'info', title: 'Abonnement', description: 'Vous êtes déjà abonné au plan Standard' });
    return <Navigate to="/mon-compte" />;
  }

  const standardSubscription = {
    label: 'standard',
    value: 9.9,
    description:
      "L'abonnement standard vous permet de profiter sans limite de l'application. Plus de soucis pour gérer vos IK !",
    features: [
      { available: true, label: 'Toutes les fonctionnalités du plan gratuit' },
      { available: true, label: 'Trajets illimités' },
      { available: true, label: 'Export comptable', tooltip: 'Accès à un fichier au format csv' },
    ],
  };

  const handleSubscriptionUpdate = async () => {
    const authenticatedUser = AuthenticationService.getAuthenticatedUser();

    if (authenticatedUser) {
      setIsLoading(true);

      const email = authenticatedUser.email ?? '';

      return createCheckoutSession('standard', authenticatedUser.uid, email).catch(() => {
        addToast({
          type: 'error',
          title: 'Souscription',
          description: "Une erreur s'est produite lors de la redirection vers la souscription de l'abonnement",
        });
        setIsLoading(false);
      });

      // Since the user is redirected
      // The we want the loading state to be active while the user is redirected
    }

    addToast({
      type: 'error',
      title: 'Souscription',
      description: 'Vous devez être connecté pour sélectionner un abonnement',
    });
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <h3 className="mt-0">Passer à l'abonnement Standard</h3>
      <p>Mettez à jour votre abonnement pour bénéficier de toutes les fonctionnalités d'Eurika</p>

      <Card className="items-start">
        <span className="p-2 mb-4 rounded-md font-bold uppercase text-white bg-primary">
          {standardSubscription.label}
        </span>
        <div>
          <span className="text-5xl font-bold">{standardSubscription.value}€</span>
          <span className="text-muted uppercase text-sm font-bold">/mois (HT)</span>
        </div>
        <p className="text-muted">
          L'abonnement standard vous permet de profiter sans limite de l'application. Plus de soucis pour gérer vos IK !
        </p>

        {standardSubscription.features.map((feature, index) => (
          <div key={index} className="flex gap-2 items-center">
            <span
              className={`block h-4 w-4 rounded-full border-2 ${
                feature.available ? 'border-primary bg-primary' : 'border-muted bg-off'
              }`}
            />

            <div className="group flex relative">
              <div>{feature.label}</div>
              {feature.tooltip !== undefined ? (
                <span className="group-hover:opacity-100 transition-opacity bg-muted p-2 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 opacity-0 m-4 z-10 w-full">
                  {feature.tooltip}
                </span>
              ) : null}
            </div>
          </div>
        ))}
      </Card>

      <Button isLoading={isLoading} color="primary" onClick={handleSubscriptionUpdate}>
        Passer à l'abonnement standard
      </Button>
    </div>
  );
};

export default SubscriptionSelectionPage;
