import { X } from 'feather-icons-react';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from './Button';

export const ModalContainer: FunctionComponent = () => {
  const modal = useNotificationsStore(state => state.modal);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (modal !== undefined) {
      setIsVisible(true);
    }
  }, [modal]);

  const handleClose = (evt?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    evt?.preventDefault();

    setIsVisible(false);
  };

  const handleAction = (evt?: React.MouseEvent<HTMLElement, MouseEvent>, action?: () => void) => {
    evt?.preventDefault();
    setIsVisible(false);
    action?.();
  };

  return (
    <>
      <input type="checkbox" id="modal" className="modal-toggle" onClick={handleClose} />
      <label htmlFor="modal" className={`modal cursor-pointer ${isVisible ? 'modal-open' : ''}`}>
        {modal ? (
          <label className="modal-box relative" htmlFor="">
            <header className="flex justify-between items-center gap-4">
              <h3 className="text-lg font-bold">{modal.title}</h3>
              <label htmlFor="modal" className="drawer-button btn btn-ghost" onClick={handleClose}>
                <X />
              </label>
            </header>

            <div className="my-4">{modal.content}</div>
            {modal.withCancelButton || (modal.actions !== undefined && modal.actions.length > 0) ? (
              <aside className="flex gap-2 justify-end items-center">
                {modal.withCancelButton ? (
                  <label htmlFor="modal">
                    <Button interactive={false} style="ghost" onClick={handleClose}>
                      Annuler
                    </Button>
                  </label>
                ) : null}

                {modal.actions?.map(({ label, action, isCTA }, index) => (
                  <label key={index} htmlFor="modal" onClick={evt => handleAction(evt, action)}>
                    <Button
                      interactive={false}
                      color={isCTA === true ? 'primary' : undefined}
                      style={isCTA !== true ? 'ghost' : undefined}
                    >
                      {label}
                    </Button>
                  </label>
                ))}
              </aside>
            ) : null}
          </label>
        ) : null}
      </label>
    </>
  );
};
