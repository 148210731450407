import { AuthenticationService, CloudFetchService, emailValidator, passwordValidator } from '@eurika/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { queryClient } from '../../main';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { Button } from '../ui/Button';
import { TextInput } from './inputs/TextInput';

interface Props {
  onSubmit?: (isConnected: boolean) => void;
}

const AccountCreationFormDataSchema = yup
  .object({
    firstName: yup.string().required('Le prénom est nécessaire'),
    lastName: yup.string().required('Le nom est nécessaire'),
    email: emailValidator,
    password: passwordValidator,
  })
  .required();

export type AccountCreationFormData = yup.InferType<typeof AccountCreationFormDataSchema>;

export const AccountCreationForm: FunctionComponent<Props> = ({ onSubmit }) => {
  const addToast = useNotificationsStore(state => state.addToast);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<AccountCreationFormData>({
    resolver: yupResolver(AccountCreationFormDataSchema),
  });

  const handleFormSubmit = async (formData: AccountCreationFormData) => {
    // Remove query cached data
    queryClient.clear();

    try {
      await CloudFetchService.createUser({
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage;

      switch (error.code) {
        case 'account/email-already-used':
          errorMessage = 'Un compte utilisant cette adresse mail existe déjà';
          break;
        default:
          errorMessage = "Le profil n'a pas pu être créé veuillez réessayer ultérieurement";
          break;
      }

      addToast({ type: 'error', title: 'Création du compte', description: errorMessage });
      return;
    }

    const loginResponse = await AuthenticationService.loginWithEmailPassword(formData.email, formData.password);
    const isConnected = loginResponse.isOk();
    onSubmit?.(isConnected);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <TextInput {...register('firstName')} error={errors.firstName} label="Prénom" />
      <TextInput {...register('lastName')} error={errors.lastName} label="Nom" />
      <TextInput {...register('email')} error={errors.email} label="Adresse mail" />
      <TextInput {...register('password')} error={errors.password} label="Mot de passe" type="password" />

      <div className="form-control mt-6">
        <Button color="primary" type="submit" isLoading={isSubmitting}>
          Créer mon compte
        </Button>
      </div>
    </form>
  );
};
