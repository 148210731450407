import { CloudFunctionService, DatabaseService } from '@eurika/core';
import { addDoc, collection, DocumentData, onSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import { z } from 'zod';

import getStripe from '../../bootstrap/initializeStripe';
import { checkoutSchema } from './models';

export type PriceId = `price_${string}`;

export const PRICES_IDS = {
  standard: import.meta.env.VITE_STRIPE_STANDARD_PRICE_ID,
} as const;

export const createCheckoutSession = async (
  subscriptionId: keyof typeof PRICES_IDS,
  uid: string,
  userEmail: string,
  targetedUserAppId = uid,
  targetedUserAppEmail = userEmail
) => {
  const firestore = DatabaseService.getDatabase();

  const checkoutCollection = collection(firestore, 'users', uid, 'checkout_sessions').withConverter({
    toFirestore<T>(data: T): DocumentData {
      return data as DocumentData;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot) {
      return checkoutSchema.partial().parse(snapshot.data());
    },
  });

  const checkoutDocument = await addDoc(checkoutCollection, {
    price: PRICES_IDS[subscriptionId],
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    allow_promotion_codes: true,
    tax_id_collection: true,
    automatic_tax: true,
    metadata: {
      ownerUserAppId: uid,
      ownerUserAppEmail: userEmail,
      targetedUserAppId,
      targetedUserAppEmail,
    },
  });

  return onSnapshot(checkoutDocument, async snapshot => {
    const snapshotData = snapshot.data();

    if (snapshotData === undefined) {
      return;
    }

    const { sessionId } = snapshotData;

    if (sessionId !== undefined) {
      const stripe = await getStripe();

      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      }
    }
  });
};

export const createCustomerPortal = async () => {
  const createPortalLinkFunction = CloudFunctionService.getFunction('ext-firestore-stripe-payments-createPortalLink');

  const { data } = await createPortalLinkFunction({
    returnUrl: window.location.origin,
    locale: 'auto',
  });

  const parsedData = z.object({ url: z.string() }).safeParse(data);

  if (parsedData.success) {
    return window.location.assign(parsedData.data.url);
  }
};
