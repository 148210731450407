import { FunctionComponent } from 'react';

export const Loader: FunctionComponent = () => {
  return <progress className="progress progress-primary w-56 "></progress>;
};

export const LoaderPage: FunctionComponent = () => {
  return (
    <div className="flex h-full w-full justify-center items-center">
      <Loader />
    </div>
  );
};
