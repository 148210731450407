import { AuthenticationService } from '@eurika/core';
import { Link, Outlet, useNavigate, useSearch } from '@tanstack/react-location';
import { FunctionComponent } from 'react';

import { Button } from '../../components/ui/Button';
import { queryClient } from '../../main';
import { useNotificationsStore } from '../../stores/notificationsStore';
import { AuthGuard } from '../../utils/auth';

const SideBar: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await AuthenticationService.logout();
    navigate({ to: '/connexion' });

    // Clear all user data to prevent cache conflict on login/logout
    queryClient.clear();

    useNotificationsStore
      .getState()
      .addToast({ type: 'info', title: 'Déconnexion', description: 'Vous avez été déconnecté' });
  };

  return (
    <div className="navbar bg-base-100 max-w-screen-lg mx-auto">
      <div className="navbar-start">
        <Link className="btn btn-ghost" to="/">
          <img className="h-3/4" src="/icon-horizontal.webp" alt="logo Eurika" />
        </Link>
      </div>

      <div className="navbar-end flex">
        <Button style="ghost" color="error" iconName="log-out" onClick={handleLogout} />
      </div>
    </div>
  );
};

export const Layout: FunctionComponent = () => {
  const search = useSearch();

  return (
    <AuthGuard redirect={search.redirect as string | undefined}>
      <div className="max-w-full h-screen overflow-y-auto bg-base-100 flex flex-col">
        <SideBar />
        <main className="flex-1 rounded-lg p-8">
          <div className="prose max-w-screen-lg mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </AuthGuard>
  );
};

export default Layout;
