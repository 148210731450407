import { Route } from '@tanstack/react-location';

import Layout from './_layout';
import BetaAccessRequest from './BetaAccessRequest';
import Contact from './Contact';
import EmailVerify from './EmailVerify';
import Login from './Login';
import NotFound from './NotFound';
import { Redirect } from './Redirect';
import ResetPassword from './ResetPassword';

// Definition of all routes
const routes: Route[] = [
  {
    path: '',
    element: <Layout />,
    children: [
      { path: '/connexion', element: <Login /> },
      { path: '/mot-de-passe-oublie', element: <ResetPassword /> },
      { path: '/verification-mail', element: <EmailVerify /> },
      { path: '/contact', element: <Contact /> },
      { path: '/acces-beta', element: <BetaAccessRequest /> },
      { path: '/redirect', element: <Redirect /> },
      { path: '**', element: <NotFound /> },
    ],
  },
];

export default routes;
